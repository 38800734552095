import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Link,
  Image,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';

import { Routes, Route } from 'react-router-dom';

import myTheme from './theme';
import Footer from './components/footer/footer.jsx';
import Navbar from './components/navbar/navbar.jsx';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Testimonial from './pages/testimonial';
import Event from './pages/events';
import Resources from './pages/resources';
import Closets from './pages/other-closets.js';
// import { getHomePageContent, getInfoCards } from './api';
import Drives from './pages/drives';
import Donate from './pages/donate';
import Shop from './pages/shop';
// import DonateVolunteer from './components/donate-volunteer/donate-volunteer.jsx';

function App() {
  return (
    <ChakraProvider resetCSS theme={myTheme}>
      <Box textAlign="center" fontSize="xl">
        <Navbar />
        <Grid minH="100vh" p={3}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/other-closets" element={<Closets />} />
            <Route path="/events" element={<Event />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/drives" element={<Drives />} />
            <Route path="/shop" element={<Shop />} />
          </Routes>
        </Grid>
        {/* <DonateVolunteer /> */}
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

export default App;
